<template>
  <div>
    <datepicker
      :inline="true"
      :disabledDates="disabledDates"
      :format="customFormatter"
      @selected="selectDay"
      @changedMonth="selectMonth"
      :language="ptBR"
      :highlighted="highlighted"
    >
    </datepicker>
    <div class="pt-4 content-title-default" v-if="unitHolidays.length > 0">
      <h5 class="calendar-title">Feriados considerados:</h5>
      <div v-for="holiday in unitHolidays" :key="holiday.id">
        <div class="text-left">
          <span class="text-secondary font-weight-bold">
            {{ holiday.date_at | moment("DD/MM") }} - {{ holiday.name }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ptBR } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      disabledDates: {
        ranges: [],
      },
      highlighted: {
        dates: [],
      },
      unitHolidays: [],
      ptBR: ptBR,
    };
  },
  components: {
    Datepicker,
  },
  computed: {
    ...mapGetters("User", ["user"]),
  },
  methods: {
    ...mapActions("Book", ["setPeriodsModal", "clearPayload"]),
    ...mapActions("Order", {
      setPeriodsOrder: "setPeriodsModal",
      clearOrder: "clearPayload",
      setLocalities: "setLocalities",
    }),
    async selectDay(date) {
      let day = this.$moment(date).format("YYYY-MM-DD");

      if (this.$route.name == "home" || this.$route.name == "books") {
        this.$root.openModal("modal-schedule");
        await this.clearPayload();
        this.setPeriodsModal({ date_at: day });
      } else {
        this.$root.openModal("modal-schedule-order");
        await this.clearOrder();
        this.setPeriodsOrder({ date_at: day });
        this.setLocalities();
      }
    },
    selectMonth(date) {
      this.$moment(date).format("YYYY-MM-DD");
    },
    customFormatter(date) {
      return this.$moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    defaultDateRange() {
      let tzoffset = new Date().getTimezoneOffset() * 60000;
      let today = new Date(Date.now() - tzoffset);

      let oldToday = new Date(today.getTime());
      oldToday.setDate(oldToday.getDate() - 1); //removi o -1 no oldToday.getDate() pois estava permitindo pedidos para o dia anterior, no passado

      today.setMonth(today.getMonth() + 1);

      let max = new Date();
      max.setDate(max.getDate() + 30);

      let toFrom = [];
      toFrom["from"] = new Date(0, 0, 0);
      toFrom["to"] = oldToday;

      this.disabledDates["ranges"].push(toFrom);
      this.disabledDates["from"] = today;
    },
  },
  mounted() {
    this.defaultDateRange();

    this.unitHolidays = this.user.unit.calendars;

    this.unitHolidays.forEach((item) => {
      this.highlighted.dates.push(new Date(item.date_at));
    });

    this.unitHolidays.sort((a, b) => {
      return new Date(a.date_at) - new Date(b.date_at);
    });
    //console.log(this.unitHolidays);
  },
};
</script>
<style>
.vdp-datepicker__calendar {
  padding: 30px 0px;
  width: 100% !important;
}
.vdp-datepicker__calendar .cell.highlighted {
  background: #005aa163 !important;
}
.calendar-title{
    font-size: 20px;
    font-family: 'Font Regular';
    color: #005AA1;
}
</style>
