var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-schedules-div"},[_vm._m(0),_c('div',{staticClass:"content-schedules-body"},[(_vm.loadingBook)?_vm._l((4),function(n){return _c('div',{key:n,staticClass:"content-schedules-body-elements"},[_c('div',{staticClass:"foods"},[_c('div',{staticClass:"foods-image"},[_c('PuSkeleton',{attrs:{"circle":"","height":"67px"}})],1),_c('div',{staticClass:"foods-text"},[_c('h3',[_c('PuSkeleton')],1),_c('p',[_c('PuSkeleton')],1)])])])}):[_vm._l((_vm.books),function(bookRow,rowIndex){return [_c('div',{key:rowIndex},[_c('div',{staticClass:"content-schedules-header"},[_c('h2',[_vm._v(_vm._s(rowIndex))])]),_vm._l((bookRow),function(book,bookIndex){return _c('div',{key:bookIndex,staticClass:"content-schedules-body-elements"},[_c('h2',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("moment")(book.date_at,"DD"))+" "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(book.date_at,"MMM"))+" ")])]),_c('div',{staticClass:"foods"},[_c('div',{staticClass:"foods-image"},[(book.menu_product.image_color)?_c('img',{staticClass:"img-fluid",attrs:{"src":book.menu_product.image_color,"alt":"image","title":"image"}}):_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/schedules/prato-personalizado.png"),"alt":"image","title":"image"}})]),_c('div',{staticClass:"foods-text"},[(book.menu)?_c('h3',{class:_vm.lastUpdateHighlight(book.menu.updated_at)
                    ? 'highlight'
                    : ''},[_vm._v(" "+_vm._s(book.menu.name)+" "),(_vm.lastUpdateHighlight(book.menu.updated_at) &&
                    book.has_done == 0 &&
                    book.last_update_by_name != null
                    )?_c('h6',[_vm._m(1,true)]):_vm._e()]):(book.menu_product && !_vm.loading)?_c('h3',{class:_vm.lastUpdateHighlight(book.menu_product.updated_at)
                    ? 'highlight'
                    : ''},[_vm._v(" "+_vm._s(_vm.findNameInsideMenu( book.period_id, book.menu_product_id, book.date_at ) || book.menu_product.name)+" "),(_vm.lastUpdateHighlight(book.menu_product.updated_at) &&
                      book.has_done == 0 &&
                      book.last_update_by_name != null
                      )?_c('h6',[_vm._m(2,true)]):_vm._e()]):_vm._e(),_c('p',[_vm._v(" ")])])])])})],2)]})]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-schedules-header"},[_c('h2',[_vm._v("Minhas Reservas: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"badge badge-pill badge-warning"},[_vm._v("O prato reservado inicialmente foi alterado por necessidade"),_c('br'),_vm._v(" do serviço, por favor, confirme ementa junto do responsável.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"badge badge-pill badge-warning"},[_vm._v("O produto reservado inicialmente foi alterado por necessidade"),_c('br'),_vm._v(" do serviço, por favor, confirme ementa junto do responsável.")])
}]

export { render, staticRenderFns }