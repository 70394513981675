<template>
  <header class="content-header d-none d-lg-block">
    <div class="content-header-itens container">
      <a class="content-header-itens-logo" href="/">
        <img
          class="img-fluid"
          src="@/assets/img/header/logo-eurest.png"
          alt="Logo Eurest"
          title="Logo Eurest"
        />
      </a>
      <div class="content-header-itens-div">
        <div class="content-header-itens-links">
          <a v-if="configs.has_books" class="markings" href="/">
            <img
              class="img-fluid"
              src="@/assets/img/dashboard/marcacoes.png"
              alt="image marcacoes"
              title="image reservas"
            />
            Reservas
          </a>
          <router-link
            v-if="configs.has_orders"
            class="markings"
            :to="{ name: 'orders' }"
          >
            <img
              class="img-fluid"
              src="@/assets/img/dashboard/pedidos.png"
              alt="image pedidos"
              title="image reservas"
            />
            Pedidos
          </router-link>
        </div>
        <div v-if="user" class="content-header-itens-amount">
          
            <h2 class="mb-4">Bem vindo(a), {{ user.name }}.</h2>
            <h2>Unidade: {{ user.unit.name }}</h2>
          
        </div>
        <div
          v-if="configs.has_recharge_balance"
          class="content-header-itens-amount"
        >
          <h2>
            O seu Saldo <span>{{ user.balance | money }}</span>
          </h2>
        </div>
        <div class="content-menu">
          <a
            @click="openMenuNav(!menuNavOpen)"
            class="content-menu-item btn-menu"
          >
            <span></span>
            <p>Menu</p>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("App", ["configs", "menuNavOpen"]),
    ...mapGetters("User", ["user"]),
  },
  methods: {
    ...mapActions("App", ["openMenuNav"]),
  },
};
</script>