<template>
  <div class="content-schedules-div">
    <div class="content-schedules-header">
      <h2>Minhas Reservas: </h2>
    </div>
    <div class="content-schedules-body">
      <template v-if="loadingBook">
        <div v-for="n in 4" :key="n" class="content-schedules-body-elements">
          <div class="foods">
            <div class="foods-image">
              <PuSkeleton circle height="67px"></PuSkeleton>
            </div>
            <div class="foods-text">
              <h3>
                <PuSkeleton></PuSkeleton>
              </h3>
              <p>
                <PuSkeleton></PuSkeleton>
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-for="(bookRow, rowIndex) in books">
          <div :key="rowIndex">
            <div class="content-schedules-header">
              <h2>{{ rowIndex }}</h2>
            </div>
            <div v-for="(book, bookIndex) in bookRow" :key="bookIndex" class="content-schedules-body-elements">
              <h2 class="date">
                {{ book.date_at | moment("DD") }}
                <span>{{ book.date_at | moment("MMM") }} </span>
              </h2>
              <div class="foods">
                <div class="foods-image">
                  <img v-if="book.menu_product.image_color" class="img-fluid" :src="book.menu_product.image_color"
                    alt="image" title="image" />
                  <img v-else class="img-fluid" src="@/assets/img/schedules/prato-personalizado.png" alt="image"
                    title="image" />
                </div>
                <div class="foods-text">
                  <h3 v-if="book.menu" :class="lastUpdateHighlight(book.menu.updated_at)
                      ? 'highlight'
                      : ''
                    ">
                    {{ book.menu.name }}

                    <h6 v-if="lastUpdateHighlight(book.menu.updated_at) &&
                      book.has_done == 0 &&
                      book.last_update_by_name != null
                      ">
                      <span class="badge badge-pill badge-warning">O prato reservado inicialmente foi alterado por
                        necessidade<br />
                        do serviço, por favor, confirme ementa junto do
                        responsável.</span>
                    </h6>
                  </h3>
                  <h3 v-else-if="book.menu_product && !loading" :class="lastUpdateHighlight(book.menu_product.updated_at)
                      ? 'highlight'
                      : ''
                    ">
                    <!-- {{ book.menu_product.name }} -->
                    {{
                      findNameInsideMenu(
                        book.period_id,
                        book.menu_product_id,
                        book.date_at
                      ) || book.menu_product.name
                    }}

                    <h6 v-if="lastUpdateHighlight(book.menu_product.updated_at) &&
                        book.has_done == 0 &&
                        book.last_update_by_name != null
                        ">
                      <span class="badge badge-pill badge-warning">O produto reservado inicialmente foi alterado por
                        necessidade<br />
                        do serviço, por favor, confirme ementa junto do
                        responsável.</span>
                    </h6>
                  </h3>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    allMenusByUnit() {
      //carregando até chegar os menus que o cliente ja pediu

      this.loading = false;
    },
  },
  computed: {
    ...mapGetters("Book", ["loadingBook", "books", "allMenusByUnit"]),
    ...mapGetters("User", ["user"]),
  },
  methods: {
    lastUpdateHighlight(updated_at) {
      //apenas mostrar o aviso se o utilizador pelo menos uma reserva
      let count = 0;
      for (let i in this.books) {
        count++;
      }
      if (count > 0) {
        //exibe o registo diferente se houve atualização nas últimas 3 horas
        let today = this.$moment();
        let diffInMinutes = Math.abs(
          this.$moment(updated_at).diff(today, "minutes")
        );
        return diffInMinutes <= 180 ? true : false;
      }
      //se não houver reservas, não exibe o aviso
      return false;
    },
    findNameInsideMenu(period_id, menu_product_id, day_at) {
      if (!this.cachedMenus) {
        this.cachedMenus = {};
      }
      let cacheKey = period_id + '-' + menu_product_id + '-' + day_at;
      if (this.cachedMenus[cacheKey]) {
        return this.cachedMenus[cacheKey].name;
      }
      let menuIndex = this.allMenusByUnit.findIndex(
        (m) => m.period_id === period_id &&
          m.menu_product_id === menu_product_id &&
          m.day_at === day_at
      );
      if (menuIndex !== -1) {
        this.cachedMenus[cacheKey] = this.allMenusByUnit[menuIndex];
        return this.allMenusByUnit[menuIndex].name;
      }
      return null;
    }
  },
  async mounted() {
    //carrega o payload para darmos o find no menu pelo nome
    this.$store.dispatch("Book/getAllMenusByUnit", {
      unit_id: this.user.unit.id,
      user_id: this.user.id,
    });
  },
};
</script>

<style scoped>
.highlight {
  text-shadow: 0px 0px 0.5px #000000 !important;
  text-underline-offset: 0.5px !important;
}
</style>
